import { Box, Modal, Button, Group, Grid, Text, Title, TextInput, Container, Card, LoadingOverlay, Badge } from '@mantine/core';
import { IconPlus, IconTrash, IconEdit, IconCrown } from '@tabler/icons-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ConsentService } from "../../services/consentService";
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { MantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';
import { ConfigContext } from '../../Context/configContext';
import { useTitle } from '../hooks/useTitle';
import { themesConfig, defaultBannerContent } from '@/utils/utils';

const tempObj = { language: 'en', domainId: '', domainUrl: '', geoLocations: [], consentExpire: '', styles: '', locations: [] };

// Define the data type for each row
type DomainData = {
    domain: string;
    consentExpire: string;
    category: string;
    status: string;
    domainId:string;
};

function DomainPage(): JSX.Element {
    const { config } = useContext(ConfigContext);
    const [data, setData] = useState<DomainData[]>([]);
    const [opened, { close, open }] = useDisclosure(false);
    const [deleteDomainId, setDeleteDomainId] = useState<any | null>(null);
    const [siteOpened, setOpened] = useState(false);
    const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    const [consentConfigRequest] = useState<any>(tempObj);
    const [loading, setLoading] = useState(false);
    const consentService = useMemo(() => new ConsentService(config!), [config]);
    const [consentExpiry, setConsentExpiry] = useState<any>('6 months');
    const [bannerLayout, setBannerLayout] = useState<any>('Box');
    const [bannerPosition, setBannerPosition] = useState<any>('leftBottomPopUp');
    const navigate = useNavigate();

    useTitle("Consent Records");

    const form = useForm({
        initialValues: { url: '' },
        validate: {
            url: (value) => !value ? 'Domain URL is required' : (urlRegex.test(value) ? null : 'Invalid domain URL'),
        },
    });

    const getTableData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await consentService.getDomains();
            console.log("API Response:", response); // Log the response
            setData(response.data);
        } catch (error) {
            console.error("Error fetching domains:", error);
            showNotification({ color: "red", message: "Failed to fetch domains." });
        } finally {
            setLoading(false);
        }
    }, [consentService]);

    useEffect(() => {
        getTableData();
    }, [getTableData]);

    const handleSave = async (values:any) => {
        
        consentConfigRequest.domainUrl = values.url;
        consentConfigRequest.language = 'en';
        consentConfigRequest.geoLocations = ['Asia-Pacific'];
        consentConfigRequest.consentExpire = '6 Months';
        consentConfigRequest.locations = ['Asia-Pacific'];

        consentConfigRequest.styles = {
            layout : bannerLayout,
            alignment : bannerPosition,
            theme : themesConfig[0],
            type  : "Light",
            bannerContent: defaultBannerContent
        };


        const response = await consentService.saveConsent(consentConfigRequest);
        if (response) {
            showNotification({ color: "green", message: "Add Domain successfully!" });
            getTableData();
        }
        setOpened(false);
    };

    const deleteDomainModal = useCallback((domainId:string) => {
        open();
        setDeleteDomainId(domainId);
    }, [open]);

    const deleteDomain = async () => {
        if (deleteDomainId) {
            await consentService.deleteDomain(deleteDomainId);
            showNotification({ color: "green", message: "Domain deleted successfully!" });
            setDeleteDomainId(null);
            getTableData();
            close();
        }
    };

    const columns = useMemo<MRT_ColumnDef<DomainData>[]>(() => [
        { accessorKey: 'domain', header: 'Domain' },
        { accessorKey: 'consentExpire', header: 'Consent Expiry' },
        { accessorKey: 'category', header: 'Plan'},
        { 
            accessorKey: 'status', 
            header: 'Status',
            Cell: ({ row }) => (
                <Badge variant="light" color={row.original.status === "Active" ? 'green':'orange'}>
                    <Text tt="capitalize" size="xs" fw={700}>
                        {row.original.status}
                    </Text>
                </Badge>
            ),
        },
        {
            header: 'Action',
            Cell: ({ row }) => (
                <Group>
                    <Link to={`/pricing?domainId=${row.original.domainId}`} onClick={(e) => {
                        e.stopPropagation(); 
                    }}>
                    <Text size="xs" c="blue">
                        <IconCrown stroke={1.3} />
                    </Text>
                    </Link>
                    <Link to="/consentBanners" onClick={(e) => {
                        e.stopPropagation(); 
                        localStorage.setItem("activeDomain", row.original.domain);
                        localStorage.setItem("activeDomainId", row.original.domainId);
                    }}>
                        <Text c="green" fw="700" style={{ cursor: 'pointer' }}>
                            <IconEdit size={22} />
                        </Text>
                    </Link>
                    <Text style={{ cursor: 'pointer' }} c="orange" fw="700" onClick={(e) => {  e.stopPropagation(); deleteDomainModal(row.original.domainId) }}>
                        <IconTrash size={22} />
                    </Text>
                </Group>
            ),
        }
    ],
    [deleteDomainModal],
    );

    return (
        <React.Fragment>
            {loading && (
                <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
            )}
            <>
                <Container fluid>
                    <Card withBorder shadow="sm" radius="md" mb="sm">
                        <Grid>
                            <Grid.Col span={{ base: 12, md: 6, lg: 10 }}>
                                <Title order={2} mb="sm">Domains</Title>
                                <Text size="sm">
                                    Add the domain names of the websites where you want to enable cookie consent. We will scan each domain for trackers.
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                                <Button onClick={() => setOpened(true)} mt="md">
                                    <IconPlus size={18} /> &nbsp; Add New Site
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Card>
                    <Box>
                        {data.length > 0 && (
                            <MantineReactTable
                                columns={columns}
                                data={data}
                                state={{ isLoading: loading }}
                                enablePagination
                                enableColumnActions={false}
                                initialState={{
                                    showGlobalFilter: true
                                }}  
                                renderToolbarInternalActions={() => {return <><div></div></>}}  
                                mantineSearchTextInputProps={{
                                    placeholder: 'Search Domain',
                                    style: { minWidth: '18rem', display:'block'},
                                    variant: 'filled',
                                }}               
                                mantineTableHeadCellProps={{
                                    style: {
                                      backgroundColor: '#f9f9f9', // Changed header background color
                                      color: '#000',               // Changed header text color
                                      fontWeight: 600,
                                      borderBottom: '2px solid #0056b3', // Darker border for header
                                      padding: '12px',             // Added padding for header cells
                                    },
                                  }}
                                  mantineTableBodyCellProps={{
                                    style: {
                                      padding: '12px',
                                      borderBottom: '1px solid #eee',
                                      transition: 'background-color 0.3s', // Smooth transition for hover effect
                                    },
                                  }}
                                  mantineTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        // Save the clicked domain details in localStorage
                                        localStorage.setItem("activeDomain", row.original.domain);
                                        localStorage.setItem("activeDomainId", row.original.domainId);
                                        // Redirect to the desired page
                                        navigate("/privacy-audits", { replace: true });
                                    },
                                    style: {
                                        cursor: 'pointer', // Change cursor to pointer on row hover
                                    },
                                  })}
                            />
                         )}
                    </Box>
                    <Modal opened={opened} onClose={close} size="sm" title="Delete Domain">
                        <Text ta="center">Are you sure to delete this domain?</Text>
                        <Group mt="xl" justify="center">
                            <Button onClick={close} variant="outline">No</Button>
                            <Button onClick={deleteDomain} variant="outline" color="red">Yes, Delete</Button>
                        </Group>
                    </Modal>
                    <Modal opened={siteOpened} onClose={() => setOpened(false)} title={null} centered withCloseButton={false} size="md">
                        <div style={{ padding: '24px', textAlign: 'center' }}>
                            <Title order={2} style={{ marginBottom: '16px' }}>Add a New Site</Title>
                            <Text size="xs" mb="xl" c="#666">
                                Adding your domain URL will start an automatic website scan.
                            </Text>
                            <form onSubmit={form.onSubmit(handleSave)}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextInput placeholder="e.g. www.cookiex.io" {...form.getInputProps('url')} style={{ marginBottom: '24px', width: '80%' }} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button fullWidth type="submit" style={{ width: '80%' }}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </Container>
            </>
        </React.Fragment>
    );
}

export { DomainPage };
